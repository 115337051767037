import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import moment from "moment";

import usePrescriptionStore from "../../../../store/modules/filterPrescriptions";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { getAllPrescriptions } from "services/api/prescription/prescriptionService";
import { SLA } from "components/widgets/molecules/SLA";
import { setLoadingOff, setLoadingOn } from "store/modules/loader";

import { TableHead } from "./TableHead";
import Search from "./Search";
import { Footer } from "./Footer";

import { PrescriptionStatusToFilter, PrescriptionType } from "utils/enum/prescription";
import { HttpStatusCode } from "types/HttpStatusCode";
import { AlertModal, MessageType } from "components/widgets/molecules/AlertModal";

export function TableList() {
    const dispatch = useAppDispatch();
    const { username } = useAppSelector((state) => state.login);

    const [tableData, setTableData] = useState([]);
    const [lastFilter, setLastFilter] = useState({ lastColumn: null, lastOrder: null });
    const [rowQuantity, setRowQuantity] = useState(20);
    const [allowCleanFilter, setAllowCleanFilter] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { filterPrescriptions, setFilterPrescriptions } = usePrescriptionStore();

    useEffect(() => {
        if (isLoading) {
            dispatch(setLoadingOn());
        } else {
            dispatch(setLoadingOff());
        }
    }, [dispatch, isLoading]);

    function removeFilterOptions() {
        const resetButton = document.querySelector('[data-testid="filterReset-button"]');

        if (resetButton?.parentElement?.parentElement) {
            resetButton.parentElement.parentElement.style.display = "none";
        }
    }

    const [searchText, setSearchText] = useState("");

    function GetLastTwoElementsWithSameUserFromAnalyseStatusHistory(analyseStatusHistory) {
        if (
            analyseStatusHistory &&
            Array.isArray(analyseStatusHistory) &&
            analyseStatusHistory.length > 1
        ) {
            const lastElement = analyseStatusHistory[analyseStatusHistory.length - 1];

            if (lastElement && lastElement.analyseStatus === 2) {
                const allKeysSameUser = analyseStatusHistory.filter((analyseHistory) => {
                    return (
                        analyseHistory.username === lastElement.username &&
                        analyseHistory.analyseStatus === 1
                    );
                });

                if (!allKeysSameUser || allKeysSameUser.length === 0) {
                    analyseStatusHistory.splice(analyseStatusHistory.length - 1, 1);
                    return GetLastTwoElementsWithSameUserFromAnalyseStatusHistory(
                        analyseStatusHistory
                    );
                }

                return [allKeysSameUser[allKeysSameUser.length - 1], lastElement];
            } else {
                analyseStatusHistory.splice(analyseStatusHistory.length - 1, 1);

                return GetLastTwoElementsWithSameUserFromAnalyseStatusHistory(analyseStatusHistory);
            }
        }

        return null;
    }

    const fetchAllPrescription = useCallback(
        async (dispacthIsLoading = false) => {
            try {
                if (dispacthIsLoading) {
                    setIsLoading(true);
                }
                const data = await getAllPrescriptions({
                    ...filterPrescriptions,
                    limitPages: rowQuantity,
                    prescriptionStatus:
                        PrescriptionStatusToFilter[filterPrescriptions.prescriptionStatus],
                    userAnalyse: username,
                });

            const tableDataArray = data?.data?.map((prescription) => {
                const automaticallyValidated = 
                    prescription?.ocrData !== null &&
                    prescription?.reasonsRejectIA === null

                const status = {
                    ...prescription.status,
                    automaticallyValidated
                }
                return { ...prescription, status};
            });

                const { lastColumn, lastOrder } = lastFilter;

                if (lastColumn) {
                    handleSort(lastColumn, lastOrder, tableDataArray);
                } else {
                    setTableData(tableDataArray);
                }
            } catch ({ response: { status } }: any) {
                if (status === HttpStatusCode.NotFound) {
                    setShowAlertModal(true);
                    setTableData([]);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [filterPrescriptions, rowQuantity, lastFilter]
    );

    useEffect(() => {
        fetchAllPrescription(true);
    }, [
        filterPrescriptions.prescriptionStatus,
        filterPrescriptions.currentPage,
        filterPrescriptions.limitPages,
        filterPrescriptions.memberId,
        filterPrescriptions.customerId,
        filterPrescriptions.username,
        filterPrescriptions.customerName,
        filterPrescriptions.startAnalyseDateTime,
        filterPrescriptions.endAnalyseDateTime,
        filterPrescriptions.prescriptionReceivedDate,
        filterPrescriptions.pagination.totalPages,
        filterPrescriptions.protocol,
    ]);

    const [dispatchFecthPrescriptions, setDispatchFecthPrescriptions] = useState(0);
    const [prescriptionFecthInterval, setPrescriptionFecthInterval] = useState(0);

    function timerToFecth() {
        const timeoutId = setTimeout(
            () => {
                if (
                    filterPrescriptions.prescriptionStatus === PrescriptionType.Pendentes ||
                    filterPrescriptions.prescriptionStatus === PrescriptionType.Todas
                ) {
                    setDispatchFecthPrescriptions(dispatchFecthPrescriptions + 1);
                }
            },
            prescriptionFecthInterval,
            filterPrescriptions.prescriptionStatus
        );

        return timeoutId;
    }

    useEffect(() => {
        if (dispatchFecthPrescriptions > 0) {
            fetchAllPrescription(false);
        }
    }, [dispatchFecthPrescriptions]);

    useEffect(() => {
        setPrescriptionFecthInterval(0);
        if (
            filterPrescriptions.prescriptionStatus === PrescriptionType.Pendentes ||
            filterPrescriptions.prescriptionStatus === PrescriptionType.Todas
        ) {
            setPrescriptionFecthInterval(1000 * 10);
        }
    }, [filterPrescriptions.prescriptionStatus]);

    useEffect(() => {
        const timeoutId = timerToFecth();

        return () => {
            clearInterval(timeoutId);
        };
    }, [timerToFecth]);

    const onFocus = () => {
        if (document.visibilityState === "visible") {
            fetchAllPrescription(false);
        }
    };

    useEffect(() => {
        window.addEventListener("visibilitychange", onFocus);

        return () => {
            window.removeEventListener("visibilitychange", onFocus);
        };
    }, []);

    function getMuiTheme() {
        return createTheme({
            overrides: {
                MUIDataTableFilter: {
                    root: {
                        backgroundColor: "#fff",
                    },
                },
                MuiToolbar: {
                    root: {
                        display: "none",
                    },
                },
            },
        });
    }

    function convertSlaTimeToHours(str) {
        // Extrai o sinal, dias, horas, minutos, segundos e milissegundos da string
        const matches = str.match(/^(-?)(\d*)?\.?(\d{2}):(\d{2}):(\d{2})\.(\d*)$/);

        if (!matches) {
            console.error("Invalid format");
        }

        const [fullStrg, sign, days, hours, minutes, seconds] = matches;

        // Converte os dias em horas
        let totalHours = Number(days || 0) * 24;

        // Converte os minutos e os segundos em horas e adiciona ao total
        totalHours += Number(hours || 0);
        totalHours += Number(minutes || 0) / 60;
        totalHours += Number(seconds || 0) / 3600;

        // Multiplica por -1 se o sinal for negativo
        if (sign === "-") {
            totalHours *= -1;
        }

        return totalHours;
    }

    const [order, setOrder] = useState("asc");

    const handleSort = (column, order, refreshData) => {
        setLastFilter({ lastColumn: column, lastOrder: order });

        let dataToSort = tableData;

        if (refreshData) {
            dataToSort = refreshData;
        }

        const sortedData = [...dataToSort].sort((a, b) => {
            let aValue: any = a[column];
            let bValue: any = b[column];

            if (column === "slaTime") {
                aValue = convertSlaTimeToHours(a[column]);
                bValue = convertSlaTimeToHours(b[column]);
            }

            if (order === "asc") {
                return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
            } else {
                return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
            }
        });
    };

    const saveRowQuantity = (qtd) => {
        setRowQuantity(qtd);
        setFilterPrescriptions({ limitPages: qtd });
    };

    const handleCloseAlertModal = () => {
        setShowAlertModal(false);
    };

    return (
        <div
            className="mx-auto mb-14 mt-12 max-w-screen-2xl px-10 xl:container xl:px-0"
            id="prescription-table"
        >
            <Search data={tableData as []} />

            <AlertModal
                close={() => handleCloseAlertModal()}
                type={MessageType.MESSAGE}
                isOpen={showAlertModal}
            />

            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    data={tableData}
                    columns={[
                        {
                            name: "protocol",
                            label: "Protocolo",
                            options: {
                                filter: false,
                                download: false,
                                print: false,
                                customBodyRender: (_, tableMeta) => {
                                    const index = tableMeta.rowIndex;

                                    const value =
                                        (tableMeta.tableData[index] as any)?.protocol ??
                                        tableMeta.tableData[index][0];

                                    return value;
                                },
                            },
                        },
                        {
                            name: "protocol",
                            label: "Protocolo",
                            options: {
                                filter: false,
                                download: true,
                                print: true,
                                display: "false",
                                customBodyRender: (_, tableMeta) => {
                                    const index = tableMeta.rowIndex;
                                    const value = tableMeta.tableData[index][0];

                                    return value;
                                },
                            },
                        },
                        {
                            name: "username",
                            label: "Nome da Vida",
                            options: {
                                filter: true,
                                download: false,
                                print: false,
                                filterOptions: {
                                    display: () => {
                                        return <input style={{ background: "red" }}>Hello</input>;
                                    },
                                },
                            },
                        },
                        {
                            name: "cardNumber",
                            label: "Member Id",
                            options: {
                                filter: true,
                            },
                        },
                        {
                            name: "customerName",
                            label: "Nome do Convênio",
                            options: {
                                filter: true,
                                customBodyRender: (customerName, tableMeta, updateValue) => {
                                    const rowNumber = tableMeta.rowIndex;
                                    return `${customerName}`;
                                },
                            },
                        },
                        {
                            name: "customerId",
                            label: "Id convênio",
                            options: {
                                filter: true,
                                download: true,
                                print: false,
                            },
                        },
                        {
                            // Created at teve que ser dividido em duas partes: a da coluna e a do filtro
                            // por causa do data/hora que o mui-datatable não estava suportando
                            name: "createdAt",
                            label: "Data e Hora Recebimento",
                            options: {
                                filter: false,
                                download: true,
                                print: true,
                                customBodyRender: (createdAt, tableMeta, updateValue) => {
                                    return moment(createdAt).format("DD/MM/YYYY HH:mm");
                                },
                            },
                        },
                        {
                            name: "createdAt",
                            label: "Data e Hora Recebimento",
                            options: {
                                viewColumns: false,
                                filter: true,
                                download: false,
                                print: false,
                                display: "false",
                                customBodyRender: (createdAt, tableMeta, updateValue) => {
                                    return moment(createdAt).format("DD/MM/YYYY");
                                },
                            },
                        },
                        {
                            name: "slaTime",
                            label: "SlaTime",
                            options: {
                                filter: false,
                                download: true,
                                print: true,
                                display: "false",
                                customBodyRender: (slaTime, tableMeta, updateValue) => {
                                    const index = tableMeta.rowIndex[0];

                                    return slaTime;
                                },
                            },
                        },
                        {
                            name: "clockRunning",
                            label: "ClockRunning",
                            options: {
                                filter: false,
                                download: true,
                                print: true,
                                display: "false",
                                customBodyRender: (clockRunning, tableMeta, updateValue) => {
                                    const index = tableMeta.rowIndex[0];

                                    return clockRunning;
                                },
                            },
                        },
                        {
                            name: "calcExpirationDate",
                            label: "CalcExpirationDate",
                            options: {
                                filter: false,
                                download: true,
                                print: true,
                                display: "false",
                                customBodyRender: (calcExpirationDate, tableMeta, updateValue) => {
                                    const index = tableMeta.rowIndex[0];

                                    return calcExpirationDate;
                                },
                            },
                        },
                        {
                            name: "expirationDate",
                            label: "SLA",
                            options: {
                                filter: false,
                                download: false,
                                print: false,
                                display:
                                    filterPrescriptions.prescriptionStatus ===
                                    PrescriptionType.Pendentes,
                                customBodyRender: (expirationDate, tableMeta, updateValue) => {
                                    const cardNumber = tableMeta.rowData[3];
                                    const customerId = tableMeta.rowData[5];
                                    const slaPrescriptionPreSend = tableMeta.rowData[17];
                                    const slaTimer = tableMeta.rowData[8];
                                    const clockRunning = tableMeta.rowData[9];
                                    const calcExpirationDate = tableMeta.rowData[10];

                                    const SLAComponent = () => (
                                        <SLA
                                            expirationDate={expirationDate}
                                            cardNumber={cardNumber}
                                            customerId={customerId}
                                            slaPreSend={slaPrescriptionPreSend}
                                            slaTimer={slaTimer}
                                            clockRunning={clockRunning}
                                            calcExpirationDate={calcExpirationDate}
                                        />
                                    );

                                    const isPending =
                                        filterPrescriptions.prescriptionStatus ===
                                        PrescriptionType.Pendentes;

                                    return isPending ? <SLAComponent /> : <p>-</p>;
                                },
                            },
                        },
                        {
                            name: "status",
                            label: "Último Status",
                            options: {
                                filter: false,
                                download: true,
                                print: true,
                                customBodyRender: (status ) => {
                                    const { description, automaticallyValidated } = status;
                                    if (description === "Receita reprovada") {
                                        return (
                                            <div className="text-center font-bold text-vidalink-magenta">
                                                Reprovada
                                            </div>
                                        );
                                    }
                                    if (
                                        description === "Receita aprovada" ||
                                        description === "Receita parcialmente aprovada"
                                    ) {
                                        return (
                                            <div className="text-center font-bold text-vidalink-green">
                                                Validada {automaticallyValidated ? 'por IA' : ''}
                                            </div>
                                        );
                                    }
                                    if (description === "Receita parcialmente aprovada") {
                                        return (
                                            <div className="text-center font-bold text-vidalink-green">
                                                Validada - {automaticallyValidated ? 'por IA' : ''}
                                            </div>
                                        );
                                    }

                                    if (description === "Pendente de Análise") {
                                        return (
                                            <div className="text-center">
                                                Pendente de
                                                <br /> Análise
                                            </div>
                                        );
                                    }

                                    return <div className="text-center">{description}</div>;
                                },
                            },
                        },
                        {
                            name: "objectId",
                            label: "Ação",
                            options: {
                                filter: false,
                                download: false,
                                print: false,
                                customBodyRender: (objectId, tableMeta, updateValue) => {
                                    const isStatusPending =
                                        tableMeta.rowData.includes("Pendente de Análise");

                                    return (
                                        <Link
                                            to={`/prescription/authorization/${objectId}`}
                                            className="block w-full cursor-pointer rounded-3xl bg-vidalink-blue p-2 text-center font-bold leading-6 text-white transition hover:opacity-90"
                                        >
                                            {isStatusPending ? "Conferir" : "Abrir"}
                                        </Link>
                                    );
                                },
                            },
                        },
                        {
                            name: "analyseStatusHistory",
                            label: "Usuário análise",
                            options: {
                                display: false,
                                viewColumns: false,
                                download: true,
                                print: true,
                                customBodyRender: (
                                    analyseStatusHistory,
                                    tableMeta,
                                    updateValue
                                ) => {
                                    const result =
                                        GetLastTwoElementsWithSameUserFromAnalyseStatusHistory(
                                            analyseStatusHistory
                                        );
                                    if (result) {
                                        return result[0].username;
                                    }

                                    return "";
                                },
                            },
                        },
                        {
                            name: "analyseStatusHistory",
                            label: "Data início análise",
                            options: {
                                display: false,
                                viewColumns: false,
                                download: true,
                                print: true,
                                customBodyRender: (
                                    analyseStatusHistory,
                                    tableMeta,
                                    updateValue
                                ) => {
                                    const result =
                                        GetLastTwoElementsWithSameUserFromAnalyseStatusHistory(
                                            analyseStatusHistory
                                        );
                                    if (result) {
                                        return moment(result[0].statusDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                        );
                                    }

                                    return "";
                                },
                            },
                        },
                        {
                            name: "analyseStatusHistory",
                            label: "Data fim análise",
                            options: {
                                display: false,
                                viewColumns: false,
                                download: true,
                                print: true,
                                customBodyRender: (
                                    analyseStatusHistory,
                                    tableMeta,
                                    updateValue
                                ) => {
                                    const result =
                                        GetLastTwoElementsWithSameUserFromAnalyseStatusHistory(
                                            analyseStatusHistory
                                        );
                                    if (result) {
                                        return moment(result[1].statusDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                        );
                                    }

                                    return "";
                                },
                            },
                        },

                        {
                            name: "slaPrescriptionPreSend",
                            label: "slaPrescriptionPreSend",
                            options: {
                                display: false,
                                viewColumns: false,
                                download: false,
                                print: false,
                                filter: false,
                            },
                        },
                    ]}
                    options={{
                        selectableRows: "none",
                        download: false,
                        print: false,
                        search: false,
                        viewColumns: false,
                        textLabels: {
                            body: {
                                noMatch: "Desculpe, nenhum resultado encontrado",
                                toolTip: "Ordenar",
                                columnHeaderTooltip: (column) => `Ordenação para ${column.label}`,
                            },
                            pagination: {
                                next: "Próxima página",
                                previous: "Página anterior",
                                rowsPerPage: "Linhas por página:",
                                displayRows: "de",
                            },
                            toolbar: {
                                search: "Procurar",
                                viewColumns: "Ver colunas",
                                filterTable: "Filtrar tabela",
                            },
                            filter: {
                                all: "Todos",
                                title: "Filtros",
                                reset: "Limpar",
                            },
                            viewColumns: {
                                title: "Mostrar colunas",
                                titleAria: "Mostrar/Esconder colunas",
                            },
                            selectedRows: {
                                text: "linhas(s) selecionadas",
                                delete: "Deletar",
                                deleteAria: "Deletar colunas selecionadas",
                            },
                        },
                        filter: false,
                        filterType: "dropdown",
                        responsive: "simple",
                        rowsPerPage: rowQuantity,
                        sort: false,
                        rowsPerPageOptions: [20, 50, 100],
                        downloadOptions: {
                            filename: `${moment().format("x")}.csv`,
                            separator: ",",
                        },
                        elevation: 0,
                        onFilterDialogOpen: () => {
                            setTimeout(() => {
                                removeFilterOptions();
                            }, 0);
                        },
                        searchText: searchText,
                        onSearchChange(searchText) {
                            setSearchText(searchText || "");
                            this.searchText = "";
                        },
                        onTableChange(action, state) {
                            if (
                                (action == "propsUpdate" || action == "viewColumnsChange") &&
                                allowCleanFilter
                            ) {
                                const displayIndex = new Set([0, 2, 3, 4, 5, 6, 9, 10]);

                                state.columns.forEach((item, index) => {
                                    if (displayIndex.has(index)) {
                                        state.columns[index].display = "true";
                                    }
                                });

                                state.filterList.forEach((item, index) => {
                                    state.filterList[index] = [];
                                });
                            }
                        },
                        searchAlwaysOpen: false,
                        customFooter: (
                            rowCount,
                            page,
                            rowsPerPage,
                            changeRowsPerPage,
                            changePage
                        ) => {
                            return (
                                <Footer
                                    rowCount={rowCount}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    changeRowsPerPage={changeRowsPerPage}
                                    changePage={changePage}
                                    saveRowQuantity={saveRowQuantity}
                                />
                            );
                        },
                    }}
                    title={false}
                    components={{
                        TableHead: (props) => (
                            <TableHead
                                {...props}
                                order={order}
                                setOrder={setOrder}
                                onSort={handleSort}
                            />
                        ),
                    }}
                />
            </ThemeProvider>
        </div>
    );
}
