export const countMatchingParts = (name1: string, name2: string): number => {
    const parts1 = name1.split(' ');
    const parts2 = name2.split(' ');

    let matches = 0;

    parts1.forEach(part1 => {
        if (parts2.some(part2 => part1.localeCompare(part2, 'pt-br', { sensitivity: 'base' }) === 0)) {
            matches++;
        }
    });

    return matches;
};
