import { Card } from "components/widgets/organisms/Card";
export function RejectIA({reasonsRejectIa}) {
    const highlightReasons = (reason) => {
        const keywords = [
            { term: "nome do paciente", bold: true },
            { term: "numero de conselho", bold: true },
            { term: "uf do conselho", bold: true },
            { term: "tipo de conselho", bold: true },
            { term: "tipificação", bold: true },
        ];

        let highlightedReason = reason;
        keywords.forEach(({ term }) => {
            const regex = new RegExp(`(${term})`, 'gi');
            highlightedReason = highlightedReason.replace(regex, '<strong>$1</strong>');
        });

        return highlightedReason;
    };

    return (
        <Card>
            <h3 className="text-[1.5rem] font-bold text-vidalink-magenta">Recusa da receita na validação automática</h3>
            <p className="font-medium">Essa receita não passou na validação automática da IA pelas seguintes razões:</p>
            <ol className="list-decimal pl-5">
                {reasonsRejectIa.map((reason, index) => (
                    <li key={index} dangerouslySetInnerHTML={{ __html: `${highlightReasons(reason)}` }} />
                ))}
            </ol>
        </Card>
    );
}