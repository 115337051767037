import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "hooks/store";
import {
    isPrescriptionPending,
    setLoadingDetailPageOn,
    setLoadingDetailPageOff,
} from "store/modules/prescriptionDetail";
import {
    updatePrescription,
    UpdatePrescriptionBodyType,
} from "services/api/prescription/prescriptionService";
import { SuccessPopup, MessageType } from "components/widgets/molecules/SuccessPopup";
import { Toast, ToastType } from "components/widgets/atoms/Toast";
import { handleAnalysisStatus } from "services/api/recipeStatus/recipeStatusService";
import { Product } from "types/Product";

type FooterProps = {
    startAnalyseDateTime: any;
    councilWarn: Boolean;
};

export function FooterActions({ startAnalyseDateTime, councilWarn }: FooterProps) {
    const { objectId } = useParams();
    const isPending = useAppSelector(isPrescriptionPending);
    const dispatch = useAppDispatch();

    const { data, reprovalReasonsIds, approvedProducts, reprovalReasonDescription, councilType } =
        useAppSelector((state) => state.prescriptionDetail);
    const {
        cardNumber,
        clientId,
        clientGroup,
        prescriptionDate,
        products,
        socialId,
        regionalCouncil,
        stateRegionalCouncil,
        username,
    } = data;

    const loggedUser = useAppSelector((state) => state.login);

    const navigate = useNavigate();

    async function handleCancel() {
        try {
            const body = {
                username: loggedUser.username,
                objectId,
                status: 2,
            };
            const response = await handleAnalysisStatus(body);
            navigate("/");
        } catch (err) {
            console.error(err);
            navigate("/");
        }
    }

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [modalType, setModalType] = useState<MessageType>(MessageType.VALIDATION);

    async function handleReprove() {
        setModalType(MessageType.REJECTION);

        const reasonsIds = reprovalReasonsIds.map((id) => parseInt(id));

        const productsList: Product[] = approvedProducts.map((product) => {
            const attributes = product?.attributes
                ? product.attributes.map((item) => handleAttrType(item)).filter((item) => item)
                : [];

            const classification = product?.classification
                ? Object.keys(product.classification)
                      .map((item) => handleAttrType(item.toUpperCase()))
                      .filter((item) => item)
                : [];

            return {
                dosage: product.dosage,
                ean: parseInt(product.ean),
                name: product.name,
                attributes: [...attributes, ...classification],
                productStatus: 
                    typeof product.productStatus === 'string'
                    ?
                        {
                            idStatus: product.idProductStatus,
                            status: product.productStatus,
                        } 
                    :
                        {
                            idStatus: product.productStatus.idStatus,
                            status: product.productStatus.status,
                        } 
            };
        });

        const body: UpdatePrescriptionBodyType = {
            username,
            cardNumber,
            clientId: clientId || clientGroup,
            objectId: objectId || "",
            prescriptionDate: prescriptionDate || "",
            products: productsList,
            regionalCouncil,
            regionalCouncilType: councilType === 0 ? 1 : councilType,
            socialId,
            startAnalyseDateTime,
            stateRegionalCouncil,
            status: {
                status: "Reproved",
                reprovalInformation: {
                    reprovalObservation: reprovalReasonDescription,
                    IdReprovalReasons: reasonsIds,
                },
            },
        };

        try {
            dispatch(setLoadingDetailPageOn());
            const response = await updatePrescription(body);

            if (!response || response?.status >= 300) {
                return toast.custom(
                    (t) => <Toast t={t} text={"Erro ao concluir receita"} type={ToastType.Error} />,
                    {
                        id: "unknown-error",
                    }
                );
            }

            setShowSuccessModal(true);
            setTimeout(() => {
                setShowSuccessModal(false);
                navigate("/");
            }, 2000);
            return;
        } catch (err) {
            return toast.custom(
                (t) => <Toast t={t} text={err.message || "Erro"} type={ToastType.Error} />,
                {
                    id: "invalid-data",
                }
            );
        } finally {
            dispatch(setLoadingDetailPageOff());
        }
    }

    async function handleSave() {
        if (councilWarn) {
            return toast.custom(
                (t) => <Toast t={t} text="CRM inválido ou inexistente" type={ToastType.Error} />,
                { id: "invalid-data" }
            );
        }
        
        const productsList: Product[] = approvedProducts.map((product) => {
            const attributes = product?.attributes
                ? product.attributes.map((item) => handleAttrType(item)).filter((item) => item)
                : [];
            
            const classification = product?.classification
                ? Object.keys(product.classification)
                      .map((item) => handleAttrType(item.toUpperCase()))
                      .filter((item) => item)
                : [];

            return {
                eanReference: product.eanReference,
                dosage: product.dosage,
                ean: parseInt(product.ean),
                name: product.name,
                attributes: [...attributes, ...classification],
                productStatus: 
                    typeof product.productStatus === 'string'
                    ?
                        {
                            idStatus: product.idProductStatus,
                            status: product.productStatus,
                        } 
                    :
                        {
                            idStatus: product.productStatus.idStatus,
                            status: product.productStatus.status,
                        } 
            };
        });

        const hasReprovalReasons = Boolean(reprovalReasonsIds.length);
        const hasApprovedProduct = approvedProducts.some(
            (product) => product.productStatus === "Approved" || product.productStatus?.status === "Approved"
        );

        if (!hasReprovalReasons && !productsList.length) {
            return toast.custom(
                (t) => (
                    <Toast
                        t={t}
                        text="A receita não possui nenhum medicamento ou motivo de reprova."
                        type={ToastType.Error}
                    />
                ),
                { id: "invalid" }
            );
        }

        const prescriptionMustBeReproved = hasReprovalReasons || !hasApprovedProduct;

        if (prescriptionMustBeReproved) {
            handleReprove();
            return;
        }

        setModalType(MessageType.VALIDATION);
        const body: UpdatePrescriptionBodyType = {
            username,
            cardNumber,
            clientId: clientId || clientGroup,
            objectId: objectId || "",
            prescriptionDate: prescriptionDate || "",
            products: productsList,
            regionalCouncil,
            regionalCouncilType: councilType === 0 ? 1 : councilType,
            socialId,
            startAnalyseDateTime: startAnalyseDateTime.toISOString(),
            stateRegionalCouncil,
            status: {
                status: "Approved",
            },
        };

        try {
            dispatch(setLoadingDetailPageOn());
            const response = await updatePrescription(body);
            setShowSuccessModal(true);
            setTimeout(() => {
                setShowSuccessModal(false);
                navigate("/");
            }, 2000);
        } catch (err) {
            dispatch(setLoadingDetailPageOff());
            const isInvalidInput = err?.response?.status === 400;

            const message = isInvalidInput
                ? "A receita não possui nenhum medicamento ou motivo de reprova."
                : "Ocorreu um erro";
            return toast.custom((t) => <Toast t={t} text={message} type={ToastType.Error} />, {
                id: "invalid-data",
            });
        }
    }

    function handleAttrType(attr) {
        switch (attr) {
            case "COBERTO":
                return 1;
            case "FOLHA":
                return 2;
            case "ISGENERIC":
                return 3;
            case "ISINTERCHANGEABLE":
                return 4;
            default:
                return null;
        }
    }

    return (
        <footer className="flex items-center justify-between">
            <div className="space-x-8">
                {isPending && (
                    <button
                        onClick={handleSave}
                        className="w-72 rounded-3xl bg-vidalink-blue p-3 text-xl font-bold text-white"
                    >
                        Concluir
                    </button>
                )}
                <button
                    onClick={handleCancel}
                    className="w-72 rounded-3xl bg-vidalink-magenta p-3 text-xl font-bold text-white"
                >
                    Cancelar
                </button>
            </div>
            <span className="block text-lg font-bold text-vidalink-blue">
                Copyright &copy; Vidalink do Brasil S/A
            </span>

            <SuccessPopup close={() => {}} isOpen={showSuccessModal} type={modalType} />

            <Toaster
                data-testid="snackbar"
                position="bottom-center"
                containerStyle={{
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
                toastOptions={{
                    duration: 3000,
                }}
            />
        </footer>
    );
}
