import { useState, useEffect } from "react";
import { Card } from "components/widgets/organisms/Card";
import { useAppDispatch, useAppSelector } from "hooks/store";
import {
    Product,
    isPrescriptionPending,
    removeApprovedProductByEan,
    removeApprovedProductByUUID,
} from "store/modules/prescriptionDetail";
import { MedicationStatus } from "components/widgets/organisms/MedicationStatus";
import Trash from "assets/icons/Trash";
import _, { indexOf } from "lodash";

const isSubsidized = "COBERTO";
const isGeneric = "GENERICO";
const isPayrolDeduction = "FOLHA";
const isReplaceable = "SUBSTITUIVEL";
const medicationStatusApproved = "Approved";
const medicationStatusReproved = "Reproved";

export function AddedMedications() {
    const dispatch = useAppDispatch();
    const approvedProducts = useAppSelector((state) => state.prescriptionDetail.approvedProducts);
    const isPending = useAppSelector(isPrescriptionPending)
    const [showOcrAddedMedications, setShowOcrAddedMedications] = useState<boolean>(false);
    const { ocrData, iAConfig } = useAppSelector((state) => state.prescriptionDetail.data);
    const ocrProducts = ocrData?.find(crop => crop.label === "nome_do_medicamento")?.ocrList

    useEffect(() => { 
        if (isPending && ocrProducts?.length > 0) {  
            setShowOcrAddedMedications(true);  
        }
    }, [approvedProducts, isPending, ocrProducts?.length]);

    const approvedProductsFromReq = useAppSelector((state) =>
        state.prescriptionDetail.data.approvedProducts
            ? state.prescriptionDetail.data.approvedProducts
            : []
    );

    const refusedProductsFromReq = useAppSelector((state) =>
        state.prescriptionDetail.data.refusedProducts
            ? state.prescriptionDetail.data.refusedProducts
            : []
    );

    let allProductsFilter: Product[] = [];

    if (approvedProductsFromReq.length === 0 || refusedProductsFromReq.length === 0) {
        allProductsFilter.push(...approvedProductsFromReq, ...refusedProductsFromReq);
    } else {
        for (const itemAprovedProduct of approvedProductsFromReq) {
            allProductsFilter.push(itemAprovedProduct);
        }

        allProductsFilter.push(...refusedProductsFromReq);
    }

    function isNullOrEmpty(value) {
        return value === null || value === undefined || value.length === 0;
      }

     function isGenericProduct(medication) {
        return medication.attributes?.includes(isGeneric) && (!isNullOrEmpty(medication.eanReference))
      }

    function handleRemove({ uuid, ean , medication , eanReference , products }) {
        if (uuid) {
            dispatch(removeApprovedProductByUUID(uuid));
        } else {       
          
            if ((isGenericProduct(medication) && (!isNullOrEmpty(eanReference)))) {

                dispatch(removeApprovedProductByEan(eanReference));
            }
            else
            {
                let indexReference;

                indexReference = products.findIndex(item => item.eanReference === medication.ean);

                if(indexReference >= 0)
                dispatch(removeApprovedProductByEan(products[indexReference].ean));
            }
          
            dispatch(removeApprovedProductByEan(ean));
        }
    }

    function isApprovedCrop (crop: any) {
        return crop?.ocr_score >= iAConfig?.thresholdMedicamentos
    }

    return (
        <>
            {isPending && approvedProducts && approvedProducts?.length > 0 ? (
                <Card data-testid="card">
                    <h3 className="mb-6 text-3xl font-bold text-vidalink-blue">
                        Medicamentos adicionados
                    </h3>

                    <div>
                        <div className="mb-2 flex gap-4 text-xl font-bold text-vidalink-blue">
                            <div className="w-68 1920:w-71 m-1280:w-66">Medicamento</div>
                            <div className="w-1/12">Posologia</div>
                            <div className="w-2/12">EAN</div>
                        </div>

                        <div className="mb-5 space-y-4">
                            {approvedProducts && approvedProducts?.map((medication, medicationIndex) => {
                                return (
                                    <div className="flex flex-col gap-4" key={medicationIndex}>
                                        {
                                            showOcrAddedMedications && ocrProducts.some(ocrProduct => ocrProduct?.medicineAdded  === medication.name) ? (

                                                <small
                                                    className={
                                                        isApprovedCrop(
                                                            ocrProducts.find(ocrProduct => ocrProduct?.medicineAdded === medication.name)
                                                        )
                                                        ? "text-vidalink-green font-medium"
                                                        : "text-vidalink-magenta font-medium"
                                                    }
                                                    key={medicationIndex}
                                                    data-testid="statusOcr"
                                                    >
                                                    {Math.floor(
                                                        ocrProducts?.find(ocrProduct => ocrProduct?.medicineAdded === medication.name)?.ocr_score * 100 || 0
                                                    )}% LEGIBILIDADE 
                                                </small>
                                            ) : (
                                                <span key={medicationIndex}>{''}</span>
                                            )
                                        }

                                        <div className="flex gap-3">
                                            <div className="form-control relative flex w-9/12">
                                                <input
                                                    type="text"
                                                    disabled
                                                    value={medication.name}
                                                    title={medication.name}
                                                />
                                            </div>
                                            <div className="form-control w-1/12">
                                                <input
                                                    type="text"
                                                    disabled
                                                    value={medication.dosage}
                                                />
                                            </div>
                                            <div className="form-control w-2/12">
                                                <input
                                                    type="text"
                                                    disabled
                                                    value={medication.ean}
                                                />
                                            </div>

                                            <button
                                                onClick={() =>
                                                    handleRemove({
                                                        uuid: medication.uuid,
                                                        ean: medication.ean,
                                                        medication: medication,
                                                        eanReference: medication.eanReference,
                                                        products: approvedProducts
                                                    })
                                                }
                                                disabled={!isPending}
                                                className="flex h-12 w-12 items-center justify-center rounded-full bg-vidalink-magenta text-white"
                                            >
                                                <Trash />
                                            </button>
                                        </div>

                                        <span
                                            className="ml-2.5 text-base font-bold"
                                            style={{ color: "#3BA648" }}
                                        >
                                            {medication.isApprovedSimilarProduct || 
                                              (isGenericProduct(medication))
                                                ? "Validado automaticamente •"
                                                : "Validado manualmente •"}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Card>
            ) : approvedProductsFromReq?.length > 0 || refusedProductsFromReq?.length > 0 ? (
                <Card data-testid="card">
                    <h3 className="mb-6 text-3xl font-bold text-vidalink-blue">
                        Medicamentos adicionados
                    </h3>

                    <div>
                        <div className="mb-2 flex gap-4 text-xl font-bold text-vidalink-blue">
                            <div className="w-68 1920:w-71 m-1280:w-66">Medicamento</div>
                            <div className="w-1/12">Posologia</div>
                            <div className="w-2/12">EAN</div>
                        </div>

                        <div className="mb-5 space-y-6">
                            {allProductsFilter.map((medication, index) => {
                                return (
                                    <div className="flex flex-col gap-4" key={index}>
                                        <div className="flex gap-3">
                                            <div className="form-control relative flex w-9/12">
                                                <input
                                                    type="text"
                                                    disabled
                                                    value={medication.name}
                                                    title={medication.name}
                                                />
                                                <MedicationStatus
                                                    medicationStatusText={String(
                                                        medication.eanReference &&
                                                            medication?.productStatus?.status ===
                                                                medicationStatusApproved
                                                            ? "Validado automaticamente"
                                                            : medication?.productStatus?.status ===
                                                              medicationStatusReproved
                                                            ? "Validado manualmente"
                                                            : medication?.productStatus?.status ===
                                                              medicationStatusApproved
                                                            ? "Validado manualmente"
                                                            : medication.eanReference &&
                                                              medication?.productStatus?.status ===
                                                                  medicationStatusReproved
                                                    )}
                                                    isReplaceable={medication.attributes.includes(
                                                        isReplaceable
                                                    )}
                                                    validDate={medication.validDate}
                                                    dueDate={medication.dueDate}
                                                    payrollDeduction={medication.attributes.includes(
                                                        isPayrolDeduction
                                                    )}
                                                    isGeneric={medication.attributes.includes(
                                                        isGeneric
                                                    )}
                                                    isSubsidized={medication.attributes.includes(
                                                        isSubsidized
                                                    )}
                                                    status={
                                                        medication?.productStatus?.status ===
                                                        "Approved"
                                                            ? "Approved"
                                                            : "Reproved"
                                                    }
                                                    key={medication.name}
                                                />
                                            </div>
                                            <div className="form-control w-1/12">
                                                <input
                                                    type="text"
                                                    disabled
                                                    value={medication.dosage}
                                                />
                                            </div>
                                            <div className="form-control w-2/12">
                                                <input
                                                    type="text"
                                                    disabled
                                                    value={medication.ean}
                                                />
                                            </div>

                                            <button
                                                onClick={() =>
                                                    handleRemove({
                                                        uuid: medication.uuid,
                                                        ean: medication.ean,
                                                        medication: medication,
                                                        eanReference: medication.eanReference,
                                                        products: allProductsFilter
                                                    })
                                                }
                                                disabled={!isPending}
                                                className="flex h-12 w-12 items-center justify-center rounded-full bg-vidalink-magenta text-white"
                                            >
                                                <Trash />
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Card>
            ) : (
                <Card>
                    <h3 className="mb-6 text-[2rem] font-bold text-vidalink-blue">
                        Medicamentos adicionados
                    </h3>
                    <p className="text-[2rem] font-medium text-vidalink-gray-700">
                        Nenhum medicamento adicionado
                    </p>
                </Card>
            )}
        </>
    );
}
