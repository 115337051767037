import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import _ from 'lodash'

export type Product = {
    uuid?: string | number;
    classification: {};
    attributes: any;
    validDate: Boolean;
    productStatus: any;
    name: string;
    ean: string;
    idProductStatus: number;
    dosage: number;
    isApprovedSimilarProduct?: boolean;
    isSingularProduct?: boolean;
    status?: string;
    eanReference?: string | number;
    dueDate?: string;
};

type ReprovalInfoType = {
    idReprovalReason: number;
    idReprovalReasons: Array<number>;
    reprovalObservation: string;
    reprovalReasonDescription: string;
    reprovalReasonsDescriptions: Array<string>;
};

type IAConfigType = {
    thresholdTypification: number;
    thresholdBeneficiario: number;
    thresholdMedico: number;
    thresholdTipoConselho: number;
    thresholdNConselho: number;
    thresholdUfConselho: number;
    thresholdDataReceita: number;
    thresholdMedicamentos: number;
}

type INITIAL_STATE = {
    data: {
        protocol: string;
        username: string;
        cardNumber: string;
        socialId: string;
        customerId: string;
        clientId: string;
        clientGroup: string;
        stateRegionalCouncil: string;
        regionalCouncil: number;
        regionalCouncilType: number;
        prescriptionDate: string | null;
        prescriptionName: string;
        files: Array<{ fileName: "" }>;
        products: Array<any>;
        approvedProducts: Array<Product>;
        refusedProducts: Array<Product>;
        status: {
            dateStatus: string;
            description: string;
            idStatus: number;
            reprovalInformation: ReprovalInfoType;
            status: string;
        };
        ocrData:  Array<any>;
        reasonsRejectIa: Array<any>;
        iAConfig: IAConfigType;
    };
    approvedProducts: Array<Product>;
    approvedSimilarProducts: Array<Product>;
    reprovalReasonsIds: Array<string>;
    reprovalReasonDescription: string;
    prescriptionDate: string;
    stateRegionalCouncil: string;
    regionalCouncil: number;
    councilType: number;
    loadingDetailPage: boolean;
    loadedRecipeCheck: boolean;
    loadedPatient: boolean;
    loadingRecipeCheck: boolean;
    loadingPatient: boolean;
};

const initialState: INITIAL_STATE = {
    data: {
        protocol: "",
        username: "",
        cardNumber: "",
        customerId: "",
        socialId: "",
        stateRegionalCouncil: "",
        regionalCouncil: 0,
        regionalCouncilType: 0,
        clientGroup: "",
        clientId: "",
        prescriptionDate: "",
        prescriptionName: "",
        files: [],
        products: [],
        approvedProducts: [],
        refusedProducts: [],
        status: {
            dateStatus: "",
            description: "",
            idStatus: 0,
            reprovalInformation: {
                idReprovalReason: 0,
                idReprovalReasons: [],
                reprovalObservation: "",
                reprovalReasonDescription: "",
                reprovalReasonsDescriptions: [],
            },
            status: "",
        },
        ocrData:  [],
        reasonsRejectIa: [],
        iAConfig: {
            thresholdTypification: 0,
            thresholdBeneficiario: 0,
            thresholdMedico: 0,
            thresholdTipoConselho: 0,
            thresholdNConselho: 0,
            thresholdUfConselho: 0,
            thresholdDataReceita: 0,
            thresholdMedicamentos: 0,
        }
    },
    approvedProducts: [],
    approvedSimilarProducts: [],
    reprovalReasonsIds: [],
    reprovalReasonDescription: "",
    prescriptionDate: "",
    regionalCouncil: 0,
    stateRegionalCouncil: "",
    councilType: 0,
    loadingDetailPage: false,
    loadedRecipeCheck: false,
    loadedPatient: false,
    loadingRecipeCheck: false,
    loadingPatient: false
};

export const PrescriptionDetailSlice = createSlice({
    name: "prescriptionDetail",
    initialState,
    reducers: {
        setDetails: (state, action: PayloadAction<INITIAL_STATE>) => {
            return {
                ...state,
                data: action.payload.data,
            };
        },
        resetDetails: (state) => {
            return initialState;
        },
        updateStateRegionalCouncil(state, action: PayloadAction<string>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    stateRegionalCouncil: action.payload,
                },
            };
        },
        updateRegionalCouncil(state, action: PayloadAction<number>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    regionalCouncil: action.payload,
                },
            };
        },
        updateTypeCouncil(state, action: PayloadAction<number>) {
            return {
                ...state,
                data: {
                    ...state.data,
                },
                councilType: action.payload,
            };
        },
        updatePrescriptionDate(state, action: PayloadAction<string | null>) {
            if (!action.payload) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                    },
                };
            }

            const prescriptionDate = new Date(action.payload).toISOString();

            return {
                ...state,
                data: {
                    ...state.data,
                    prescriptionDate,
                },
            };
        },
        updatePatient: (state, action: PayloadAction<any>) => {
            const { name, cardNumber } = action.payload;

            return {
                ...state,
                data: {
                    ...state.data,
                    username: name,
                    cardNumber: cardNumber,
                },
            };
        },
        updateApprovedProducts (state, action: PayloadAction<Array<Product>>) {
            return {
                ...state,
                approvedProducts: action.payload,
            };
        },
        addApprovedProduct: (state, action: PayloadAction<Product[]>) => {
            const payloadArray = Array.isArray(action.payload) ? action.payload : [action.payload];
            const newApprovedProducts = [...state.approvedProducts, ...payloadArray];

            return {
                ...state,
                approvedProducts: newApprovedProducts,
            };
        },
        addApprovedSimilarProduct: (state, action: PayloadAction<Product>) => {
            state.approvedSimilarProducts.push(action.payload);
        },
        removeApprovedProductByUUID: (state, action) => {
            return {
                ...state,
                approvedProducts: state.approvedProducts.filter(
                    (item) => item.uuid !== action.payload
                ),
            };
        },
        removeApprovedProductByEan: (state, action) => {
            return {
                ...state,
                approvedProducts: state.approvedProducts.filter(
                    (item) => item.ean !== action.payload
                ),
            };
        },
        resetApprovedProducts: (state) => {
            return {
                ...state,
                approvedProducts: [],
            };
        },
        setReprovalReasonDescription(state, action: PayloadAction<string>) {
            return {
                ...state,
                reprovalReasonDescription: action.payload,
            };
        },
        setReprovalReasons: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                reprovalReasonsIds: action.payload,
            };
        },
        resetReprovalReasons: (state) => {
            return {
                ...state,
                reprovalReasonsIds: [],
            };
        },
        setLoadingDetailPageOn: (state) => {
            return { ...state, loadingDetailPage: true }
        },
        setLoadingDetailPageOff: (state) => {
            return { ...state, loadingDetailPage: false }
        },
        setLoadingRecipeCheckOn: (state) => {
            return { ...state, loadingRecipeCheck: true }
        },
        setLoadingRecipeCheckOff: (state) => {
            return { ...state, loadingRecipeCheck: false }
        },
        setLoadedRecipeCheckOn: (state) => {
            return { ...state, loadedRecipeCheck: true }
        },
        setLoadedPatientOn: (state) => {
            return { ...state, loadedPatient: true }
        },
        setLoadingPatientOn: (state) => {
            return { ...state, loadingPatient: true }
        },
        setLoadingPatientOff: (state) => {
            return { ...state, loadingPatient: false }
        }
    },
});

export const {
    setDetails,
    resetDetails,
    updateStateRegionalCouncil,
    updateRegionalCouncil,
    updateTypeCouncil,
    updatePrescriptionDate,
    updatePatient,
    updateApprovedProducts,
    addApprovedProduct,
    addApprovedSimilarProduct,
    removeApprovedProductByEan,
    removeApprovedProductByUUID,
    resetApprovedProducts,
    resetReprovalReasons,
    setReprovalReasons,
    setReprovalReasonDescription,
    setLoadingDetailPageOn,
    setLoadingDetailPageOff,
    setLoadedRecipeCheckOn,
    setLoadingRecipeCheckOn,
    setLoadingRecipeCheckOff,
    setLoadingPatientOn,
    setLoadingPatientOff,
    setLoadedPatientOn
} = PrescriptionDetailSlice.actions;

export const selectPrescriptionDetail = (state: RootState) => state.prescriptions;
export const isPrescriptionPending = (state: RootState) =>
    state.prescriptionDetail.data.status.status === "AnalisyPending";

export default PrescriptionDetailSlice.reducer;
